import { createSlice } from "@reduxjs/toolkit";
import { LANG } from "../core/constants";
import { getData, postData, putData, fetchAll } from "../core/fetchService";
import { showError } from "../core/utils";
import { SOME_SETTINGS } from "../core/constants";
import { clearFilters } from "./filters.js";
import i18next from "i18next";

import Location                                          from "../core/location";

const _setState = name => (state, data) => {
  state[name] = data;
};

const use = set => (state, action) => {
  set(state, action.payload);
};

const setState = name => use(_setState(name));

const updateListState = name => (state, action) => {
  const obj = action.payload;
  state.modelList = state[name].map(d => d._id == obj._id ? obj : d);
};

// const _setField =          _setState('field');

export const settings = createSlice({
  name: "settings",
  initialState: {
    lang: LANG.EN,
    user: null,
    userName: "Unknown user",
    url: null,
    isLoading: false,
    token: null,
    oauthServer: null,
    locationPath: "",
    /*
    users: null,
    userGroups: null,
    */
    projects: null,
    projectId: null,
    projectDatasets: [],
    selectedDataSetId: null,
    slotsList: null,
    intentsList: null,
    lookupList: null,
    modelList: null,
    modelConfigList: [],
    testModelList: null,
    autoModelList: null,
    assotiatedEntitiesList: null, // TODO move to slots.js
  },
  reducers: {
    setLang: (state, action) => {
      state.lang = action.payload;
      i18next.changeLanguage(state.lang);
    },
    setUser:                                    setState('user'),
    setToken:                                   setState('token'),
    setRefreshToken:                            setState('setRefreshToken'),
    setOauthServer:                             setState('oauthServer'),
    setIsLoading:                               setState('isLoading'),
    /*
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setUserGroups: (state, action) => {
      state.userGroups = action.payload;
    },
    */
    setProjectId: (state, action) => {
      const id = action.payload;
      state.projectId = id;
      if (id)
        state.projectDatasets = state.projects.find(p => p._id == id).datasets;
    },
    setProjects:                                setState('projects'),
    setProjectDatasets: (state, action) => {
      state.projectDatasets = action.payload;
      state.projects = state.projects.map(p => p.id == state.projectId
                                          ? ({ ...p, datasets: action.payload }) : p);
    },
    setDatasetId: (state, action) => {
      const id = action.payload || state.projectDatasets?.[0]?._id;
      if (id)
        localStorage.setItem("datasetId", id);
      else
        localStorage.removeItem("datasetId");
      state.selectedDataSetId = id;
    },
    setLocationPath:                            setState('locationPath'),
    setLookupList:                              setState('lookupList'),

    updateDataset: (state, action) => {
      const dataset = action.payload;
      state.projectDatasets = state.projectDatasets.map(ds => ds._id == dataset._id ? dataset : ds);
    },
    addToLookupDict: (state, action) => {
      const { lookupId, value } = action.payload;

      if (!state.lookupList[lookupId].values)
        state.lookupList[lookupId].values = [value];
      else
        state.lookupList[lookupId].values.push(value);
    },
    updateLookupDict: (state, action) => {
      const { lookupId, values, value } = action.payload;

      if (!state.lookupList[lookupId].values)
        state.lookupList[lookupId].values = values;
      else if (value) {
        state.lookupList[lookupId].values = state.lookupList[lookupId].values.map(l => {
          if (l._id === value._id) return value;
          return l;
        });
      }
    },
    setIntentsList:                             setState('intentsList'),
    updateIntent:                               updateListState('intentsList'),
    setSlotsList:                               setState('slotsList'),
    setSlots:                                   setState('slots'),
    setModelConfigList:                         setState('modelConfigList'),
    setModelList:                               setState('modelList'),
    updateModel:                                updateListState('modelList'),
    setTestModelList:                           setState('testModelList'),
    setAutoModelList:                           setState('autoModelList'),

    setAssotiatedEntitiesList:                  setState('assotiatedEntitiesList'),
  },
});

export const {
  setLang, setToken, setRefreshToken, /* setUsers, setUserGroups, */
  setIsLoading, setOauthServer, setLocationPath, updateModel, updateIntent,
  updateDataset, updateLookupDict, addToLookupDict, setAssotiatedEntitiesList,
} = settings.actions;

const {
  setUser, setDatasetId, setProjects, setProjectId, setProjectDatasets,
  setIntentsList, setLookupList, setSlotsList, setSlots, setModelConfigList,
  setModelList, setTestModelList, setAutoModelList,
} = settings.actions;

export const getLang =                    state => state.settings.lang;
export const getToken =                   state => state.settings.token;
export const getRefreshToken =            state => state.settings.setRefreshToken;
export const getUserName =                state => state.settings.user.name;
export const getIsLoading =               state => state.settings.isLoading;
export const getOauthServer =             state => state.settings.oauthServer;
/*
export const getUsers =                   state => state.settings.users;
export const getUserGroups =              state => state.settings.userGroups;
*/
export const getLocationPath =            state => state.settings.locationPath;
export const getProjects =                state => state.settings.projects;
export const getProjectId =               state => state.settings.projectId;
export const getProjectDatasets =         state => state.settings.projectDatasets;
export const getSelectedDataSetId =       state => state.settings.selectedDataSetId;
export const getIntentsList =             state => state.settings.intentsList;
export const getSlotsList =               state => state.settings.slotsList;
export const getLookupList =              state => state.settings.lookupList;

export const setAuthData = data => async dispatch => { // async is necessary if a caller wants .then()
  localStorage.setItem(SOME_SETTINGS.TOKEN, JSON.stringify({
    'userName': data.user.username,
    'userGroups': data.user.groups,
    'token': data.token,
    'refreshToken': data.refreshToken,
  }));
  dispatch(setUser(data.user));
  dispatch(setToken(data.token));
  Location.replace('/projects');
};

export const setOAuthData = _data => dispatch => {
  dispatch(setAuthData({
    user: _data,
    token: _data.accessToken,
    refreshToken: _data.refreshToken,
    oauth: true,
  })).then(() => {
    window.location.reload();
  });
};

/*
export const fetchUsers = () => dispatch =>
  getData(`/api/user`, dispatch, data => dispatch(setUsers(data.users)));

export const fetchUserGroups = () => dispatch =>
  getData(`/api/user_groups`, dispatch, data => dispatch(setUserGroups(data.user_groups)));
*/

export const fetchProjects = callback => dispatch => 
  getData(`/api/project`, dispatch, data => {
    callback ? callback(data) : dispatch(setProjects(data.projects));
  });

export const initGlobals = (projects, projectId, t) => dispatch => {
  if (!Location.isKnownRoute()) {
    showError(dispatch, t)('common.error_unknown_section', Location.getSection());
    Location.replace('/projects');
  }
  const id = Location.getProjectId() || projectId;
  const ds_id = Location.getSection() == 'annotation' ? Location.getDatasetId()
    : id == projectId ? localStorage.getItem('datasetId')
    : null;
  // console.log('initGlobals', projects, projectId, id, Location.getSection(), Location.getDatasetId());
  return projects
    ? dispatch(changeProject(id, null, ds_id))
    : dispatch(fetchProjects(({ projects }) => {
      const wrong_p = id && !projects.find(p => p._id == id);
      const wrong_d = id && !wrong_p && ds_id && !projects.find(p => p._id == id).datasets.find(d => d._id == ds_id);
      if (wrong_p) {
        showError(dispatch, t)('projects.error_not_found', id);
        Location.replace('/projects');
      }
      if (wrong_d) {
        showError(dispatch, t)('datasets.error_not_found', ds_id);
        Location.replace('/datasets/'+ id);
      }
      dispatch(setProjects(projects));
      dispatch(changeProject(wrong_p?null:id, projects, wrong_d?null:ds_id));
    }));
};

export const fetchProjectDataSets = (projectId, { stat = true } = {}) => dispatch =>
  getData(`/api/project/${projectId}`, dispatch, data => {
    dispatch(setProjectDatasets(data.project.datasets));
    if (stat)
      dispatch(fetchDataSetSizes(data.project.datasets));
  });

export const fetchDataSets = ({ datasets = [], adjust = _ => _ }) => dispatch =>
  Promise.all(datasets.map(ds => getData(`/api/dataset/${ds._id}`, dispatch, data => {
    dispatch(updateDataset(adjust(data.dataset)));
  })));

const fetchDataSetSizes = datasets => dispatch => {
  const BATCH_SIZE = datasets.length; // seems no need in batching now // 10;
  for (let i=0; i<datasets.length; i+=BATCH_SIZE) {
    postData('/api/dataset/stats', {
      datasets: datasets.slice(i,i+BATCH_SIZE).map(d => d._id),
    }, dispatch, stats => {
      dispatch(setProjectDatasets(datasets.map((ds,j) => {
        return j<i || j>=i+BATCH_SIZE ? ds : { ...ds, stat: stats[ds._id] };
      })));
    });
  }
};

export const fetchIntents = projectId => dispatch =>
  getData(`/api/intent?project=${projectId}`, dispatch, data =>
    dispatch(setIntentsList(data.intents)));

export const fetchSlots = projectId => dispatch =>
  getData(`/api/slot?project=${projectId}`, dispatch, data => {
    dispatch(setSlotsList(data.slots));
    dispatch(setLookupList(data.lookups));
  });

export const fetchAllSlots = projectId => dispatch =>
  getData(`/api/slot?project=${projectId}`, dispatch, data => {
    const entityIds = (s) => {
      let names = [];
      //push
      (s?.entityIds || []).forEach(id => {
        names = [...names, data.lookups[id]?.name || id];
      });

      return names.join(", ");
    };

    dispatch(setSlots(
      data.slots.map(s => ({
        _id: s._id,
        name: s.name,
        entityIds: entityIds(s),
      })),
    ));

    dispatch(setAssotiatedEntitiesList(
      Object.keys(data.lookups).map(_id => {
        return ({
          value: _id,
          label: `${data.lookups[_id].scope}.${data.lookups[_id].name}`,
          scope: data.lookups[_id].scope,
          name: data.lookups[_id].name,
        });
      }),
    ));
  });

export const fetchAddToLookup = (obj, callback) => dispatch =>
  postData(obj.url, obj.data, dispatch, data => callback && callback(data));

export const fetchUpdateInLookup = (obj, callback) => dispatch =>
  putData(obj.url, obj.data, dispatch, data => callback && callback(data));

export const fetchEntityValuesByLookupId = obj => dispatch => {
  const { lookupId } = obj;
  return getData(`/api/lookup/${lookupId}`, dispatch, data => {
    return dispatch(updateLookupDict({ lookupId, values: data.lookup?.values || [] }));
  });
};

export const fetchModelConfigList = projectId => dispatch =>
  getData(`/api/model_config?project=${projectId}`, dispatch, data =>
    dispatch(setModelConfigList(data.model_configs)));

export const fetchModelList = projectId => (dispatch, state) =>
  getData(`/api/model?project=${projectId}`, dispatch, data =>
    dispatch(setModelList(data.models || [])));

export const fetchTestModelList = projectId => dispatch =>
  getData(`/api/testmodel?project=${projectId}`, dispatch, data =>
    dispatch(setTestModelList(data)));

export const fetchAutoModelList = projectId => dispatch =>
  getData(`/api/automodel?project=${projectId}`, dispatch, data =>
    dispatch(setAutoModelList(data)));

export const refreshToken = () => dispatch => {
  const storage = JSON.parse(localStorage.getItem(SOME_SETTINGS.TOKEN));
  if (storage)
    postData(
      `/token`,
      { refreshToken: storage.refreshToken },
      dispatch,
      ({ token } = {}) => {
        if (token)
          localStorage.setItem(SOME_SETTINGS.TOKEN, JSON.stringify({ ...storage, token }));
        else
          localStorage.clear();
      }
    );
};

export const changeProject = (_id, projects, _ds_id) => dispatch => {
  const id = _id || projects?.[0]?._id;
  const ds_id = _ds_id || projects?.find(p => p._id == id)?.datasets[0]?._id;
  dispatch(setProjectId(id));
  dispatch(setDatasetId(ds_id));
  dispatch(clearFilters({ but: ['projects'] }));
  Location.changeProject(id, ds_id);
};

export const changeDataset = id => dispatch => {
  dispatch(setDatasetId(id));
  // dispatch(clearFilters({ only: ['annotation', 'sim']})); // TODO: unite w/dataset change
};

export default settings.reducer;

import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Dialog, DialogTitle, DialogContent, DialogActions,
  Table, TableBody, TableRow, TableCell,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { showDate } from "../../../core/utils";
import CloseBar from "../../components//dialogCloseBar";

export function BatchStatusDialog(props) {
  const { isOpen, onClose } = props;
  const [statusBody, setStatusBody] = useState(props.statusBody);

  const { t } = useTranslation();

  useEffect(() => {
    if (props.batches && statusBody?.steps && isOpen) {
      if (props.batches.find(b => b._id === statusBody._id).execution?.steps !== statusBody.execution.steps) {
        setStatusBody(props.batches.find(b => b._id === statusBody._id).execution)
      }
    }
  }, [props.batches]);

  let _k = 1;
  const span = (s,t) => <span key={_k++} style={s}>{t}</span>;
  const bold = t => span({fontWeight: "bold"}, t);
  const italic = t => span({fontStyle: "italic"}, t);

  const getDescr = step => {
    switch (step.name) {
    case "TRAIN":
      return italic(` "${props.modelsList.find(m => m._id === props.statusBatch.nlu_model_2)?.name}"`);
    case "ANNOTATE":
      const a = props.autoModelList.find(a => a._id === props.statusBatch.automodel);
      return [
        italic(` "${props.datasetsList.find(d => d._id === a?.dataset)?.name}" `),
        bold(t("batches.with_model")),
        italic(` "${a?.name}"`),
      ];
    case "TEST":
      const { obj_key } = step;
      const tm = props.testsList.find(t => t._id === props.statusBatch[obj_key]);
      return [
        italic(` "${tm?.name}" `),
        bold(t("batches.with_dataset")),
        italic(` "${props.datasetsList.find(d => d._id === tm?.dataset)?.name}"`),
      ];
    default: // avoid 'no-default' warning
      break;
    }
    return "";
  };

  const error_message = typeof props.statusBody?.error == 'string'
    ? props.statusBody?.error : JSON.stringify(props.statusBody?.error);

  return (
    <Fragment>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
        open={isOpen}
        onClose={onClose}
        onKeyUp={(event) => event.key == "Escape" && onClose()}
        aria-labelledby="edit-model-status-dialog-title"
      >
        <CloseBar onClose={onClose} title={t("batches.exec_status")}/>
        <DialogContent dividers={true}>

          <Table size="small">
            <TableBody>
              {props.statusBody?.status != 'Error'
                ? <TableRow>
                  <TableCell>{t("batches.status")}:</TableCell>
                  <TableCell>{props.statusBody?.status}</TableCell>
                </TableRow>
                : <TableRow>
                  <TableCell>{t("batches.error")}:</TableCell>
                  <TableCell>{error_message}</TableCell>
                </TableRow>}
              <TableRow>
                <TableCell>{t("batches.start")}:</TableCell>
                <TableCell>{showDate(props.statusBody?.started)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("batches.finish")}:</TableCell>
                <TableCell>{showDate(props.statusBody?.finished)}</TableCell>
              </TableRow>
              {(props.statusBody?.steps || []).map(step =>
              <Fragment key={step.name}>
                <TableRow>
                  <TableCell>{[bold(t("batches."+ step.name)), getDescr(step)]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{paddingLeft: 30}}>{t("batches.startAt")}:</TableCell>
                  <TableCell>{showDate(step.startedAt)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{paddingLeft: 30}}>
                    {step.finishedAt ? t("batches.finishAt") : props.statusBody?.error ? t("batches.error") : ""}:
                  </TableCell>
                  <TableCell>
                    {showDate(step.finishedAt) || error_message || "In progress..."}
                  </TableCell>
                </TableRow>
              </Fragment>)}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" autoFocus>
            {t("common.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

import React              from 'react';
import { useTranslation } from 'react-i18next';

import Button          from '@material-ui/core/Button';
import Dialog          from '@material-ui/core/Dialog';
import DialogActions   from '@material-ui/core/DialogActions';
import DialogContent   from '@material-ui/core/DialogContent';

import { DIALOG_USER_STATE }    from '../../core/constants';
import CloseBar                 from '../components/dialogCloseBar';

export default function ConfirmDialog(props) {
    const {
      open = true,
      closeModal,
      title,
      btnNameAgree,
      btnNameDisagree,
      showBtnNameAgree = true,
      maxWidth = 'sm',
      fullWidth = true,
      contentProps: _cp = {},
    } = props;

    const contentProps = {
      ..._cp,
      dividers: _cp?.divivers == undefined ? true : _cp?.divivers,
    };

    const { t } = useTranslation();

    return (
        <div>
            <Dialog
                fullWidth={fullWidth}
                open={open}
                maxWidth={maxWidth}
                onClose={closeModal}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
              <CloseBar onClose={closeModal} title={title}/>
                <DialogContent
                  {...contentProps}
                >
                    <div id="dialog-description">
                        {props.content}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeModal(DIALOG_USER_STATE.DISAGREE)} color="primary">
                        {btnNameDisagree || t('common.disagree')}
                    </Button>
                    {/* eslint-disable-next-line no-undef */}
                    {showBtnNameAgree &&
                        <Button onClick={() => closeModal(DIALOG_USER_STATE.AGREE)} color="primary" autoFocus>
                            {btnNameAgree || t('common.agree')}
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}

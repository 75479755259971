import React, { Fragment } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import {
  getProjectId,
  fetchProjects,
  changeProject,
} from "../../../features/settings";
import { postData, deleteData } from '../../../core/fetchService'
import { clearFilters } from "../../../features/filters";
import { PROJECT_TYPE_AC_OPTS, DIALOG_USER_STATE } from '../../../core/constants';
import EnhancedTable from '../../components/projectTable';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import EditIcon from '@material-ui/icons/Edit';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../../components/confirmDialog'
import Radio from '@material-ui/core/Radio';
import { withTranslation } from 'react-i18next';
import { IsAdmin } from '../../../core/utils';
import { withStyles } from "@material-ui/styles";
import EditProject from "./edit"
import CloseBar from "../../components/dialogCloseBar";
import iconList from '../../components/helpers/iconList';
import Scrollable from '../../components/helpers/scrollable';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
  },
  paper: {
    padding: "10px",
    textAlign: "left",
  },
  column: {
    flexBasis: "33.33%",
  },
  heading: {
    fontSize: "12",
  },
  gridList: {
    overflow: "hidden",
  },
  summary: {
    marginLeft: "20px",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  tdTopCell: {
    borderTop: "1px solid #000",
  },
  textField: {
    margin: 8
  }
});

function TableCellRadioButton(props) {
  const projectId = useSelector(getProjectId);
  const dispatch = useDispatch();
  const { id } = props;
  return (
    <TableCell padding="checkbox">
      <Radio
        checked={id == projectId}
        onChange={() => dispatch(changeProject(id))}
      />
    </TableCell>
  )
}

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: null,
      name: "",
      description: "",
      editingProjectId: null,
      training_port: null,
      runtime_port: null,
      showModal: false,
      modalContent: null,
      openEditDialog: false
    };
    this.scrollable = new Scrollable(this);
  }

  getAllProjects = () => this.props.dispatch(fetchProjects());

  setCurrentProject = id => {
    this.props.dispatch(changeProject(id, this.props.projects));
  };

  componentDidMount() {
    this.scrollable.componentDidMount();
  };

  handleEditRow = (state, id) => {
    this.setState({ openEditDialog: state, editingProjectId: id })
  };

  handleDeleteRow = (id, name) => {
    this.setState({ modalContent: `${this.props.t('projects.delete_question')}: "${name}"?`, showModal: true, rowId: id });
  };

  handleCloseModal = modalState => {
    const { dispatch, projectId } = this.props;
    const { rowId } = this.state;
    this.setState({ showModal: false });
    if (modalState == DIALOG_USER_STATE.AGREE && rowId)
      deleteData(`/api/project/${rowId}`, dispatch, async () => {
        await this.getAllProjects();
        if (rowId == projectId)
          await this.setCurrentProject();
      });
  };

  render() {
    // console.log('RENDER: projects');

    const { showModal, modalContent } = this.state;
    const { t, projects } = this.props;

    const headCells = [{
        _id: 'name',        width: "40%",   label: t('common.project'), textSearch: true,
/*
    }, {
        _id: 'type',        width: "15%",   label: t('common.type'),
        filterOn: true,
*/
    }, {
        _id: 'description', width: "auto",  label: t('common.description'), align: 'left', textSearch: true,
    }];

    const rows = projects && projects.map(p => (
      { _id: p._id, name: p.name, type: p.type || 'NLU', description: p.description }
    ));

    return (
      <Fragment>
        {showModal && <ConfirmDialog title={t('projects.confirm_title')} open={showModal} content={modalContent} closeModal={(modalState) => this.handleCloseModal(modalState)} />}
        {rows && 
          <>
            <EnhancedTable
              id="projects"
              useLocationSearch
              orderValue={'name'}
              headCells={headCells}
              rows={rows}
              toolBarName={t('menu.projects')}
              newRowTitle={t("projects.add_new_project")}
              handleClickUpdateRow={this.getAllProjects}
              handleClickNewRow={IsAdmin() ? () => this.handleEditRow(true, null) : null}
              checkBoxTableCell={id => <TableCellRadioButton id={id}/>}
              customBtns={(name, id) => (
                <div style={{width: "15%", display: "flex"}}>
                  {iconList([
                    ['common.edit',   EditIcon,   () => this.handleEditRow(true, id)],
                    ['common.delete', DeleteIcon, () => this.handleDeleteRow(id, name)],
                  ], { t })}
                </div>
              )}
            />
            {this.state.openEditDialog && (
              <EditProject
                id={this.state.editingProjectId}
                handleCloseEdit={saved => {
                  saved && this.state.editingProjectId && this.getAllProjects();
                  this.handleEditRow(false, null);
                }}
                onCreateOrUpdate={id => {
                  this.getAllProjects().then(() => this.setCurrentProject(id));
                }}
              />
            )}
          </>
        }
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.settings.projects,
  projectId: state.settings.projectId,
});

export default connect(mapStateToProps)(withStyles(styles)(withTranslation()(Project)));

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import './css/index.css';

import App from './App';
import store from './app/store';
import * as serviceWorker from './other/serviceWorker';

import './i18n/index.js';

const theme = createMuiTheme({
	overrides: {
		MuiTableCell: {
			root: {
				padding: '2px'
			}
		}
	}
});

ReactDOM.render(
	<Provider store={store}>
		<MuiThemeProvider theme={theme}>
			<App/>
		</MuiThemeProvider>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

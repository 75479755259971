import { createSlice } from '@reduxjs/toolkit';

export const filters = createSlice({
  name: 'filters',
  initialState: {},
  reducers: {
    setFilter: (state, action) => {
      const { id, filter } = action.payload;
      state[id] = filter;
    },
    deleteFilter: (state, action) => {
      delete state[action.payload];
    },
    clearFilters: (state, action) => {
      const { but = [], only } = action.payload;
      Object.keys(state).forEach(id => {
        if ((!only || only.includes(id)) && !but.includes(id))
          delete state[id];
      });
    },
  },
});

export const { setFilter, deleteFilter, clearFilters } = filters.actions;

export const getFilter = id => state => state.filters[id];

export default filters.reducer;

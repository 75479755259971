import React from "react";

import { getRunConfigOption }         from "../../../core/utils";
import { viewLink }                   from "../../components/projectTable";

export function viewStatus(row, cell, { s_obj, percent, in_progress: _ip }) {
  const q_pos = s_obj?.progress?.queued;
  const in_progress = [_ip].flat().map(s => s +'...').includes(s_obj?.status);

  return viewLink(row, cell, !in_progress ? undefined : _ => (
      q_pos != undefined
        ? `Queued: ${q_pos+1}` : `${s_obj.status.replace('...','')}: ${Math.floor(percent || 0)}%`
  ));
}

export const linkStyle = (getStatusObj, add_colors) => row => {
  const [s_obj, is_running] = getStatusObj(row);
  const status = !is_running || s_obj?.progress?.queued === undefined
    ? (s_obj?.status || '').replace(/\W.*/,'')
    : 'Queued';
  const colors = {
    Error: 'red',
    Ready: 'green',
    Aborting: 'darkblue',
    Aborted: 'black',
    Stopped: 'black',
    Lost: 'grey',
 // Waiting: 'lightblue',
 // Queued: '#12afe3',
    ...add_colors,
  };
  return { color: colors[status] || "blue" };
};

export function Runnable({
    taskNames,
    getTaskName,
    getStatus,
    statusName,
    updateStatus,
    REFRESH_INTERVAL = 5,
    addActiveStatuses = [],
}) {
  this.objs = null;

  this.isStatusRunning = s => s == statusName +'...';
  this.isStatusActive = s =>
    ['Preparing', 'Sent', statusName, 'Aborting', 'Waiting', ...addActiveStatuses].map(s => s +'...').includes(s);

  const isMyTask = o => !taskNames || taskNames.includes(getTaskName(o));
  this.isObjRunning = o => isMyTask(o) && this.isStatusRunning(getStatus(o));
  this.isObjActive =  o => isMyTask(o) && this.isStatusActive(getStatus(o));

  const getObjById =  (objs, id) => objs.find(o => o._id == id);

  this.isRunning =  (objs, id) => this.isObjRunning(getObjById(objs, id));
  this.isActive =   (objs, id) => this.isObjActive(getObjById(objs, id));

  const isAnyObjActive = objs => !objs || objs.find(this.isObjActive);

  const setRefreshInterval = () => { this._timer = setInterval(_updateStatus, REFRESH_INTERVAL*1000) };
  const clearRefreshInterval = () => clearInterval(this._timer);

  this.componentDidUpdate = objs => {
    this.objs = objs;
    clearRefreshInterval();
    isAnyObjActive(objs) && setRefreshInterval();
  };
  this.componentWillUnmount = () => {
    this.objs = null;
    clearRefreshInterval();
  };

  const _updateStatus = () => {
    const active_objs = (this.objs || []).filter(this.isObjActive);
    updateStatus(active_objs);
  };
}

export function Runnables(rs) {
  const my = this;
  Object.entries(rs).forEach(([k,v]) => { my[k] = v });

  const runnables = Object.values(rs);
  this.isObjActive = o =>  runnables.some(r => r.isObjActive(o));
  this.isObjRunning = o => runnables.some(r => r.isObjRunning(o));

  this.isRunning = (objs, i) => runnables.some(r => r.isRunning(objs, i));
  this.isActive =  (objs, i) => runnables.some(r => r.isActive(objs, i));

  this.componentDidUpdate = objs => { runnables.forEach(r => r.componentDidUpdate(objs)) };
  this.componentWillUnmount = () => { runnables.forEach(r => r.componentWillUnmount()) };
}


import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { fetchAllSlots } from '../../../features/settings';
import { getData, postData, deleteData, putData, uploadFile } from '../../../core/fetchService';
import { DIALOG_USER_STATE } from '../../../core/constants';
import EnhancedTable from '../../components/projectTable';
import IconButton from '../../components/material-ui/IconButton';
import iconList from '../../components/helpers/iconList';
import DeleteIcon from '@material-ui/icons/Delete';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import EditIcon from '@material-ui/icons/Edit';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ConfirmDialog from '../../components//confirmDialog'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import Upload from '../../components/fieldUpload';
import { download, GlobalState } from '../../../core/utils';
import { withTranslation, useTranslation } from 'react-i18next';
import CloseBar from "../../components/dialogCloseBar";
import notBackdropClicked from "../../components/helpers/notBackdropClicked";
import Scrollable from '../../components/helpers/scrollable';

function EditDialog(props) {
	const { isOpen, assotiatedEntitiesList, onClose, slot } = props;
	const [name, setName] = useState("");
	const [assotiatedEntity, setAssotiatedEntity] = useState([]); //label:"system.color" name:"color" scope:"system" value:"5e74e3f6e30cd115fb69ca25"
	const { t } = useTranslation();

	useEffect(() => {
		setName((slot && slot.name) || "");
		setAssotiatedEntity(slot?.entityIds?.map(entityId => assotiatedEntitiesList.find(a => a.value === entityId)) || []);
	}, [slot]);

	const handleClose = (isOpen) => {
		if (!isOpen) handleClear()

		const entityIds = assotiatedEntity.map(a => a?.value);
		onClose({ name, entityIds }, isOpen, (slot && slot._id) || null);
	};

	const handleClear = () => {
		setName('');
		setAssotiatedEntity([])
	};

	return (
		<Dialog
			fullWidth={true}
			open={isOpen}
			onClose={notBackdropClicked(() => handleClose(false))}
			aria-labelledby="dialog-title"
			aria-describedby="dialog-description"
		>
			<CloseBar onClose={() => handleClose(false)} title={!slot ? t('slots.add_slot') : t('slots.edit_slot')}/>
			<DialogContent dividers style={{ overflow: 'hidden' }}>
				<TextField
					required size="small" style={{ margin: 8 }}
					label={t('slots.slot')}
					onChange={(event) =>{
						let value = event.target.value;
						value = value.replace(/[^0-9A-Za-z-_.]/gi, '');
						setName(value);
					}}
					variant="outlined"
					fullWidth
					value={name || ''}
				/>
				{assotiatedEntitiesList && isOpen && <Autocomplete
					multiple
					size="small"
					fullWidth
					style={{ margin: 8 }}
					options={assotiatedEntitiesList}
					groupBy={(option) => option.scope}
					getOptionLabel={(option) => option?.name}
					value={assotiatedEntity}
					onChange={(event, value) => { setAssotiatedEntity(value) }}
					filterSelectedOptions
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							label={t('slots.entities')}
						/>
					)}
				/>}

			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleClose(false)} color="primary">
					{t('common.cancel')}
				</Button>
				{/* eslint-disable-next-line no-undef */}
				<Button onClick={() => handleClose(true)} color="primary" autoFocus>
					{t('common.save')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

class Slots extends React.Component {
	constructor(props) {
		super(props);
		const { t } = props;
		this.state = {
			//rows: null,
			showModal: false,
			modalContent: null,
			rowId: null,
			editDialogOpen: false,
			//assotiatedEntitiesList: null,
			slot: null
		};
    this.scrollable = new Scrollable(this);
    this.globalState = new GlobalState(this.getAllSlots);
	}

	getAllSlots = () => {
		this.props.dispatch(fetchAllSlots(this.props.projectId));
	}

	componentDidMount() {
    this.scrollable.componentDidMount();
    this.globalState.componentDidMount();
	}

	componentDidUpdate(prevProps) {
    this.globalState.componentDidUpdate(prevProps, this.props);
  }

	componentWillUnmount() {
		this.abortController.abort();
	}

	abortController = new window.AbortController();

	handleEditRow = (id, name) => {
		getData(`/api/slot/${id}`, this.props.dispatch, data => {
      this.setState({ slot: data.slot, editDialogOpen: true });
    });
  };

	handleDeleteRow = (id, name) => {
		const { t } = this.props;
		this.setState({ modalContent: `${t('slots.delete_slot')}: "${name}"?`, showModal: true, rowId: id });
	};

	handleCloseModal = (modalState) => {
		this.setState({ showModal: false });

		if (modalState == DIALOG_USER_STATE.AGREE)
      deleteData(`/api/slot/${this.state.rowId}`, this.props.dispatch, data => {
        this.getAllSlots();
      })
	};

	handleClickNewRow = () => {
		this.setState({ editDialogOpen: true, slot: null });
	};

	handleClose = (dialogState, isOpen, rowId) => {
		const { t, dispatch } = this.props;

		if (dialogState.keyCode == 27 || !isOpen)
			return this.setState({ editDialogOpen: false });

    const sendData = rowId ? putData : postData;

    sendData(`/api/slot${rowId ? '/'+rowId : ''}`, {
      slot: { ...dialogState, project: this.props.projectId }
    }, dispatch, () => {
      this.getAllSlots();
      this.setState({ editDialogOpen: false });
    }, {
      error_prefix: t('slots.save_error'),
    });
	};

	handleClickUploadSlots = (fileType, formData) => {
		const { t, dispatch } = this.props;

    uploadFile(`/api/project/${this.props.projectId}/data_schema/upload`, formData, dispatch, data => {
      this.getAllSlots();
    }, {
      error_prefix: t('train.upload_model_error'),
    });
	};

	handleClickDownLoadSlots = () =>
    download(`/api/project/${this.props.projectId}/data_schema/download`, this.props.dispatch);

	render() {
		const { modalContent, showModal, editDialogOpen, slot } = this.state;
		const { t, slots, assotiatedEntitiesList } = this.props;

		const headCells = [
			{ _id: 'name',      width: "40%",  label: t('slots.slot'),                    textSearch: true },
			{ _id: 'entityIds', width: "auto", label: t('slots.entities'), align: 'left',   filterOn: true },
		];

		return (
			<Fragment>
        {showModal && <ConfirmDialog
          open={showModal}
          title={t('slots.confirm_title')}
          content={modalContent}
          closeModal={(modalState) => this.handleCloseModal(modalState)}
        />}
        <EditDialog
          {...this.props}
          slot={slot}
          isOpen={editDialogOpen}
          onClose={(obj, isOpen, rowId) => this.handleClose(obj, isOpen, rowId)}
          assotiatedEntitiesList={assotiatedEntitiesList}
        />
        {slots && <EnhancedTable
					id="slots"
          useLocationSearch
					headCells={headCells}
					rows={slots}
					toolBarName={t('menu.slots')}
					newRowTitle={t("slots.add_slot")}
					handleClickNewRow={() => this.handleClickNewRow()}
					handleClickUpdateRow={this.getAllSlots}
					handleRowItemChanged={() => this.handleRowItemChanged}
					checkBoxTableCell={(id, name, index) => (
						<TableCell padding="default">{index + 1}</TableCell>
					)}
					customHeaderButtons={() => {
						return <Fragment>
							<Upload
								btnComponent={<IconButton title={t('common.upload')} Icon={PublishIcon} component="span"/>}
								fileNameShow={false}
								size="small"
								onFileLoad={(fileType, formData) => this.handleClickUploadSlots(fileType, formData)}
							/>
              <div style={{margin: 10}}>
                {iconList([
                  ['common.download', GetAppIcon, this.handleClickDownLoadSlots],
                ], { t })}
              </div>
						</Fragment>
					}}
					customBtns={(name, id) => (
						<div style={{width: "25%", display: "flex"}}>
              {iconList([
                ['common.edit',   EditIcon,   () => this.handleEditRow(id, name)],
                ['common.delete', DeleteIcon, () => this.handleDeleteRow(id, name)],
              ], { t })}
						</div>
					)}
				/>
				}
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	projectId: state.settings.projectId,
	slots: state.settings.slots,
	assotiatedEntitiesList: state.settings.assotiatedEntitiesList
});

export default withRouter(connect(mapStateToProps)(withTranslation()(Slots)));

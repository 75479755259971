import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  makeStyles,
} from "@material-ui/core";

import Upload from "../../components/fieldUpload";
import CloseBar from "../../components/dialogCloseBar";
import notBackdropClicked from "../../components/helpers/notBackdropClicked";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  formControl: {
    marginTop: 20,
    paddingTop: 5,
  },
  legend: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const propTypes = Object.freeze({
  isOpen: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
});

export default function AddDialog({ filterName, isOpen, onAccept, onReject }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [auto, setAuto] = useState(true);
  const [file, setFile] = useState();

  useEffect(
    () => {
      setName(filterName || "");
      setFile();
    },
    [isOpen]
  );

  const handleAcceptClick = () => {
    onAccept({ name: name || file.name.replace('.json',''), file });
  };

  const handleRejectClick = () => {
    onReject();
  };

  const handleNameChange = (value) => {
    if (auto)
      setName(value);
  };

  const handleFileLoad = (name, form) => {
    setFile({ name, form });
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      scroll="paper"
      onClose={notBackdropClicked(handleRejectClick)}
    >
      <CloseBar onClose={handleRejectClick} title={t("filters."+ (filterName ? 'edit_filter' : 'add_filter'))}/>
      <DialogContent dividers>
        <div style={{padding: 8}}>
          <TextField
            required
            fullWidth
            autoFocus
            name="name"
            size="small"
            margin="dense"
            variant="outlined"
            value={name}
            label={t("filters.filter_name")}
            onChange={e => { const n = e.target.value; setAuto(!n.trim()); setName(n) }}
          />
        </div>
        {!filterName && <div style={{margin: 8}}>
          <Upload passOutside={handleNameChange} onFileLoad={handleFileLoad}/>
        </div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRejectClick}>
          {t("common.cancel")}
        </Button>
        <Button
          disabled={!name && !file}
          onClick={handleAcceptClick}
        >
          {t("common."+ (filterName ? 'save' : 'add'))}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddDialog.propTypes = propTypes;

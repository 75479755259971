import React from "react";
import MaterialUIRadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

export default function RadioGroup(props) {
  const {
    /* extra props, required */
    labels,
    name,
    value,
    disabled_labels = [],

    /* extra props, optional */
    labelPlacement = "start",
    legend = null,

    /* defaults for mui RadioGroup's props */
    row = true,

    ...other_props
  } = props;

  const lprops = {
    ...{ row },
    ...other_props,
  };

  return (
    <MaterialUIRadioGroup
      name={name}
      { ...lprops }
    >
      {legend &&
      <FormLabel
        className={legend.classes}
        component="legend"
      >
        {legend.name}
      </FormLabel>}
      {
        Object.entries(labels).map(([name, text]) => (
          <FormControlLabel
            key={name}
            value={name}
            control={<Radio/>}
            label={text}
            labelPlacement={labelPlacement}
            checked={value == name}
            disabled={disabled_labels.includes(name)}
          />
        ))
      }
    </MaterialUIRadioGroup>
  );
}

import React from "react";
import IconButton from '../material-ui/IconButton';

export default (icon_descr, { t, size = 'small', fontSize = 'default', hidden = false }) =>
  icon_descr.map(([name, Icon, onClick]) => (
    <IconButton
      key={name}
      title={t(name)}
      onClick={onClick}
      Icon={Icon}
      size={size}
      fontSize={fontSize}
      style={{visibility: hidden ? 'hidden' : 'visible'}}
    />
  ));


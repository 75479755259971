import React, { useState } from "react";

import MuiMenu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import IconButton from "./IconButton";

export function SimpleMenu({ Icon, choices, title, handleOpen, handleClose, disabled }) {
  const [anchorElem, setAnchorElem] = useState();
  const onOpen = event => {
    setAnchorElem(event.currentTarget);
    handleOpen && handleOpen();
  };
  const onClose = key => () => {
    setAnchorElem(undefined);
    handleClose && handleClose(key);
  };
  return (<>
    <IconButton title={title} onClick={onOpen} Icon={Icon} disabled={disabled}/>
    <MuiMenu
      anchorEl={anchorElem}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      getContentAnchorEl={null} // else warning from MUI Popover (underneath Menu)
      open={Boolean(anchorElem)}
      onClose={onClose()}
    >
      {Object.entries(choices).map(([key, label]) => (
        <MenuItem
          key={key}
          onClick={onClose(key)}
        >
          {label}
        </MenuItem>
      ))}
    </MuiMenu>
  </>);
}

import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { withTranslation, useTranslation } from "react-i18next";

// import { fetchUsers } from "../../../features/settings";
import { getRunConfigOption, groupById } from "../../../core/utils";
import { getData } from "../../../core/fetchService";
import EnhancedTable from "../../components/projectTable";

import TextField from "@material-ui/core/TextField";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

const Users = (props) => {
  const [users, setUsers] = useState(null);
  const [groups, setGroups] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    getUsers();
  }, [props.projectId]);

  const getUsers = async () => {
    await getData(`/api/user`,        dispatch, data => setUsers(data.users));
    await getData(`/api/user_group`,  dispatch, data => setGroups(groupById(data.user_groups)));
  };

  const postUser = async (obj, clone) => {
  };

  const deleteUser = async (id) => {
  };

  const updateUser = async (obj) => {
  };

  const headCells = [
    { _id: "email",     label: t("users.email") },
    { _id: "admin",     label: t("users.admin") },
    { _id: "groups",    label: t("users.groups") },
    { _id: "username",  label: t("users.name") },
    { _id: "createdAt", label: t("users.createdAt"), dateTime: true },
  ];

  const handleClickRow = () => {};

  const getGroupName = g => (groups || {})[g]?.name;
  const isAdmin = u => (u.groups || []).some(g => getGroupName(g) == 'admin');

  return (
    <Fragment>
      {users && <EnhancedTable
        id="users_table"
        useLocationSearch
        headCells={headCells}
        rows={users.map(u => ({
          ...u,
          admin: isAdmin(u) ? '+' : '',
          groups: (u.groups || []).map(g => getGroupName(g)).join(', '),
        }))}
        toolBarName={t("menu.users")}
        newRowTitle={t("users.new_user")}
        handleClickNewRow={() => handleClickRow() }
        handleClickUpdateRow={getUsers}
        checkBoxTableCell={(id, name, index) => (
          <TableCell padding="default">{index + 1}</TableCell>
        )}
        customBtns={(name, id) => {
          const user = users?.find(b => b._id === id) || { userSteps: [] };
          return <Fragment>
            <IconButton size="small" aria-label="edit" onClick={() => handleClickRow(id)}>
              <Tooltip title={t("common.edit")}>
                <EditIcon/>
              </Tooltip>
            </IconButton>
            <IconButton size="small" aria-label={t("common.clone")} onClick={() => {
            }}>
              <Tooltip title={t("common.clone")}>
                <FileCopyIcon/>
              </Tooltip>
            </IconButton>
            <IconButton size="small" aria-label={t("common.delete")}
              onClick={() => {/* handleDeleteDialogOpen(id, name) */}}>
              <Tooltip title={t("common.delete")}>
                <DeleteIcon/>
              </Tooltip>
            </IconButton>
          </Fragment>;
        }}
      />}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  projectId: state.settings.projectId,
  projects: state.settings.projects,
  projectDatasets: state.settings.projectDatasets,
});

export default withRouter(connect(mapStateToProps)(withTranslation()(Users)));
;

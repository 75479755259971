import { createSlice } from '@reduxjs/toolkit';
import { MESSAGE_STATUS } from '../core/constants';

export const messageInfo = createSlice({
	name: 'messageInfo',
	initialState: {
		snackBarState: false,
		snackBarMessages: Array(10),
		backDropClose: false
	},
	reducers: {
		setMessageState: (state, action) => {
      let { snackBarMessages: message, snackBarVariant: variant, snackBarState: sb_state = true } = action.payload;
			if (variant === MESSAGE_STATUS.ERROR)
				message = `Error: ${message}`;

			state.snackBarMessages = [{ message, variant }, ...state.snackBarMessages.slice(0,-1)];
			state.snackBarState = sb_state;
		},
		closeMessageInfo: (state, action) => {
			state.snackBarState = !action.payload;
		},
		setBackDropOpen: (state, action)=>{
			state.backDropClose = action.payload;
		}
	},
});

export const { setMessageState, closeMessageInfo, setBackDropOpen } = messageInfo.actions;

export const getMessageState = state => {
	return state.messageInfo;
}

export const getBackDropClose = state => state.messageInfo.backDropClose;

export default messageInfo.reducer;

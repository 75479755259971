import { createSlice } from '@reduxjs/toolkit';

export const annotationSettings = createSlice({
  name: 'annotationSettings',
  initialState: {
    isExpanded: [0] //only one entity, replace later
  },
  reducers: {
    setExpandedList: (state, action) => {
      const { _id, stillOpen } = action.payload;
      state.isExpanded = state.isExpanded.find(id => id === _id)
        && !stillOpen ? state.isExpanded.filter(id => id !== _id) : [...state.isExpanded, _id];
    },
    expandAllRows: (state, action) => {
      state.isExpanded = action.payload;
    },
    collapseAllRows: (state) => {
      state.isExpanded = [];
    },
  },
});

export const {
  setExpandedList,
  expandAllRows,
  collapseAllRows,
} = annotationSettings.actions;

export const getExpandedList = state => state.annotationSettings.isExpanded;

export default annotationSettings.reducer;

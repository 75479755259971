import React, { Fragment } from "react";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import {
  Button,
  Dialog, DialogContent, DialogActions,
  Table, TableBody, TableRow, TableCell,
} from "@material-ui/core";
import { useTranslation } from "../../../core/utils";
import CloseBar from "../../components//dialogCloseBar";

export function ModelStatusDialog(props) {
  const { isOpen, onClose, model = {}, trainable } = props;
  const { t } = useTranslation();

  const { name, type, is_external, filename, model_server_url, remote_name, runtime_name, status, error, startTrainAt, finishTrainAt, deployedAt, progress, result, createdAt, updatedAt } = model;

  const bold = n => <span style={{fontWeight: 'bold'}}>{n}</span>;
  const red = n => <span style={{color: 'red'}}>{n}</span>;

  return (
    <Fragment>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        scroll="paper"
        open={isOpen}
        onClose={onClose}
        aria-labelledby="edit-model-status-dialog-title"
      >
        <CloseBar onClose={onClose} title={t("train.status_dialog_title", {name})}/>
        <DialogContent dividers={true}>

          <Table size="small">
            <TableBody>
              {error ? <TableRow>
                <TableCell align="left">{red(bold(t("train.error")))}</TableCell>
                <TableCell align="left">{error.error || error}</TableCell>
              </TableRow> : null}
              {error && error.json ? <TableRow>
                <TableCell align="left" colSpan={2} padding="none">
                  {JSON.stringify(error.json, null, 2)}
                </TableCell>
              </TableRow> : null}
              {remote_name && !["Aborted", "Aborting..."].includes(status) ?
              <TableRow>
                <TableCell align="left">{t("train.remote_name")}</TableCell>
                <TableCell align="left">{remote_name}</TableCell>
              </TableRow>: null}
              {is_external
                ? <TableRow>
                  <TableCell align="left">{bold(t("train.createdAt"))}</TableCell>
                  <TableCell align="left">{new Date(createdAt).toLocaleString()}</TableCell>
                </TableRow>
                : <TableRow>
                  <TableCell align="left">{bold(t("train.train_start"))}</TableCell>
                  <TableCell align="left">{startTrainAt ? new Date(startTrainAt).toLocaleString() : ""}</TableCell>
                </TableRow>}
              {status == 'Train...' && progress && progress.queued === undefined
                ? [['epochs', progress._outer], ['batches', progress]].map(([key, p]) => p ? (
                    <Fragment key={key}>
                      <TableRow>
                        <TableCell>{bold(t("train."+key))}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t("progress.items")}</TableCell>
                        <TableCell>{p.current} {t("progress.items_of")} {p.total}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t("progress.percent")}</TableCell>
                        <TableCell>{p.percent}%</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t("progress.time_elapsed")}, {t('common.seconds_a')}</TableCell>
                        <TableCell>{p.time_total}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t("progress.time_left")}, {t('common.seconds_a')}</TableCell>
                        <TableCell>{p.est_time_left}</TableCell>
                      </TableRow>
                    </Fragment>): (
                      <TableRow key={key}>
                          <TableCell>{bold(t("train."+key))}: no data</TableCell>
                      </TableRow>
                )) : null}
              {status == 'Ready' && result ?
              <TableRow>
                <TableCell align="left">WRR tuned/base</TableCell>
                <TableCell align="left">{result.tuned?.WRR} / {result.base?.WRR}</TableCell>
              </TableRow> : null}
              {is_external
                ? updatedAt && updatedAt != createdAt ? <TableRow>
                  <TableCell align="left">{bold(t("train.updatedAt"))}</TableCell>
                  <TableCell align="left">{new Date(updatedAt).toLocaleString()}</TableCell>
                </TableRow> : null
                : <TableRow>
                  <TableCell align="left">{bold(status != "Aborted" ? t("train.train_end") : t("train.train_abort"))}</TableCell>
                  <TableCell align="left">
                    {!trainable.isStatusActive(status) && progress?.queued === undefined && finishTrainAt
                      ? new Date(finishTrainAt).toLocaleString() : ""}
                  </TableCell>
                </TableRow>}
              {deployedAt ? (<>
                <TableRow>
                  <TableCell align="left">{t("train.runtimeName")}</TableCell>
                  <TableCell align="left">{runtime_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">{bold(t("train.deployedAt"))}</TableCell>
                  <TableCell align="left">{new Date(deployedAt).toLocaleString()}</TableCell>
                </TableRow>
              </>) : null}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" autoFocus>
            {t("common.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

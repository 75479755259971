import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from "@material-ui/icons/GetApp";
import { withRouter } from "react-router";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { withTranslation, useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { NLU_TYPE_AC_OPTS } from "../../../core/constants";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import AddBoxIcon from "@material-ui/icons/AddBox";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import ExpandLess from   "@material-ui/icons/ExpandLessOutlined";
import ExpandMore from   "@material-ui/icons/ExpandMoreOutlined";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import Highlighter from "react-highlight-words";
import ClearIcon from "@material-ui/icons/Clear";
import { deleteData, getData, postData, putData } from "../../../core/fetchService";
import { setIsLoading, fetchModelConfigList } from "../../../features/settings";
import { DIALOG_USER_STATE, FILE_FORMATS } from "../../../core/constants";
import SaveIcon from "@material-ui/icons/Save";
import ConfirmDialog from "../../components/confirmDialog";
import { download } from "../../../core/utils";
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/yaml/yaml";
import "../../../css/codeMirror.css";
import IconButton from "../../components/material-ui/IconButton";
import Scrollable from '../../components/helpers/scrollable';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  codeMirrorClass: {
    height: "500px",
  },
  paper: {
    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "5px",
    marginBottom: theme.spacing(2),
  },
  title: {
    flex: "1 1 100%",
    textAlign: "left",
  },
  list: {
    overflowY: "auto",
    height: "700px",
    minWidth: "200px",
    marginTop: "23px",
    borderRadius: "5px",
    padding: 0,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  listItemText: {
    width: "50%",
  },
  descriptionField: {
    marginBottom: "10px",
    backgroundColor: "white"
  },
  config: {
    flexGrow: 1,
    width: "100%",
  },
  configOutline: {
    borderWidth: "0 !important",
  },
  tabs: {
    width: "100%",
  },
  formControl: {
    marginTop: 20,
    paddingTop: 5,
  },
  legend: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  dialogField: {
    marginTop: "10px",
    marginBottom: "10px",
    backgroundColor: "white",
    width: "30%",
  }
}));

const Configs = (props) => {
  const configs = {
    custom: props.modelConfigList?.filter(mc => mc.scope == "custom") || [],
    public: props.modelConfigList?.filter(mc => mc.scope == "public") || [],
  };
  const codemirrorRef = useRef(null);
  const nameRef = useRef(null);

  const newConfig = {
    project: props.projectId,
    name: "",
    description: "",
    scope: "custom",
    data: "",
    type: NLU_TYPE_AC_OPTS[0].value
  };

  const { t } = useTranslation();

  const classes = useStyles();

  const [descriptionFocus, setDescriptionFocus] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [currentConfig, setCurrentConfig] = useState(newConfig);
  const [openListItem, setOpenListItem] = useState({
    custom: false,
    public: false,
  });
  const [deleteConfigDialogState, setDeleteConfigDialogState] = useState({
    isOpen: false,
    content: "",
    configId: "",
  });
  const [getawayDialogState, setGetawayDialogState] = useState({
    isOpen: false,
    content: "",
    getawayId: true,
  });

  const isEqualObj = (obj1, obj2) => {
    if (Object.entries(obj1).length !== Object.entries(obj2).length)
      return false;

    for (let key in obj1)
      if (obj1[key] !== obj2[key])
        return false;

    return true;
  };

  const equalToOrigConfig = () => {
    const origConfig = configs[currentConfig.scope].find(c => c._id === currentConfig._id);

    if (origConfig && isEqualObj(origConfig, currentConfig)) return true;

    return false;
  };

  const scrollable = new Scrollable(null, { props });

  useEffect(() => {
    if (!currentConfig._id) {
      void nameRef?.current.focus();
    } else {
      void codemirrorRef?.current.editor.focus();
      void codemirrorRef?.current.editor.setCursor({ line: 0, ch: 0 });

      /** void codemirrorRef?.current.editor.focus() auto-scrolls down, leaving tab header
       *  with title/buttons hidden, thus using scrollable to scroll back
       */
      scrollable.componentDidMount();
    }
  }, [currentConfig._id]);

  useEffect(() => {
    setCurrentConfig(newConfig);
    fetchConfigs();
  }, [props.projectId]);

  const fetchConfigs = () => {
    props.dispatch(fetchModelConfigList(props.projectId));
  };

  useEffect(() => {
    setOpenListItem({
      custom: configs.custom.length !== 0,
      public: configs.public.length !== 0,
    });
    if (!currentConfig._id)
      setCurrentConfig(configs.custom && configs.custom[0]
                       || configs.public && configs.public[0]
                       || newConfig);
  }, [props.modelConfigList]);

  const downloadConfig = async () =>
    download(`/api/model_config/download/${currentConfig._id}`, props.dispatch);

  const createConfig = async () => {
    postData(
      "/api/model_config",
      { model_config: currentConfig },
      props.dispatch,
      data => {
        const { __v, ...mc } = data.model_config;
        setCurrentConfig(mc)
        fetchConfigs();
      }
    )
  };

  const getConfig = async (id, func) => {
    getData(
      `/api/model_config/${id}`,
      props.dispatch,
      data => {
        const { __v, ...mc } = data.model_config
        if (func) func(mc)
      }
    )
  };

  const cloneConfig = async () => {
    const { projectId, dispatch } = props;
    const model_config = {
      scope: 'custom', // overwrite scope
      project: projectId,
    };
    postData(`/api/model_config/copy/${currentConfig._id}`, { model_config }, dispatch, data => {
      const { __v, ...mc } = data.model_config;
      setCurrentConfig(mc);
      fetchConfigs();
    });
  };

  const updateConfig = async () => {
    await putData(`/api/model_config/${currentConfig._id}`, 
      { model_config: currentConfig},
      props.dispatch,
      data => {
        const { __v, ...mc } = data.model_config;
        setCurrentConfig(mc);
        fetchConfigs();
      }
    );
  };

  const deleteConfig = () => {
    deleteData(`/api/model_config/${deleteConfigDialogState.configId}`, props.dispatch, () => {
      setCurrentConfig(newConfig);
      fetchConfigs();
    });
  };

  const handleDeleteDialogClose = async (result) => {
    if (result === DIALOG_USER_STATE.AGREE) {
      await deleteConfig();
    }

    setDeleteConfigDialogState({
      isOpen: false,
      content: null,
      configId: null,
    });
  };

  const handleDeleteDialogOpen = (configId, configName) => {
    setDeleteConfigDialogState({
      configId,
      isOpen: true,
      content: `Delete config: "${configName}"?`,
    });
  };

  const handleGetawayDialogOpen = (id) => {
    setGetawayDialogState({
      ...getawayDialogState,
      isOpen: true,
      content: t("configs.getaway_body"),
      getawayId: id,
    });
  };

  const handleGetawayDialogClose = (result) => {
    if (getawayDialogState.getawayId) {
      if (result === DIALOG_USER_STATE.AGREE) {
        getConfig(getawayDialogState.getawayId, setCurrentConfig);
      }
    } else {
      setCurrentConfig(newConfig);
    };

    setGetawayDialogState({
      isOpen: false,
      content: undefined,
    });
  };

  const handleCleanUpFilterSearch = () => setSearchFilter("");
  const handleFilterSearch = event =>     setSearchFilter(event.target.value);

  const ifDisabled = (type, name) =>
    type == "button" && !(currentConfig._id || name == 'save')
      || !['clone','download'].includes(name) && currentConfig.scope == 'public';

  const iconFontSize = { fontSize: "small" };

  return (
    <div className={classes.root}>
      <ConfirmDialog
        title={t("configs.delete_config")}
        open={deleteConfigDialogState.isOpen}
        content={deleteConfigDialogState.content}
        closeModal={handleDeleteDialogClose}
      />
      <ConfirmDialog
        title={t("configs.getaway")}
        open={getawayDialogState.isOpen}
        content={getawayDialogState.content}
        closeModal={handleGetawayDialogClose}
      />
      <Grid container
        wrap="nowrap"
        style={{ overflow: "auto" }}>
        <Grid
          item
          xs={3}
          sm={3}
          style={{ padding: "10px", minHeight: "650px", minWidth: "200px" }}
        >
          <Toolbar variant="dense">
            <Typography
              component={"span"}
              className={classes.title}
              variant="h6"
              id="tableTitle"
            >
              {t("menu.configs")}
            </Typography>
            <IconButton
              title={t("configs.new")}
              Icon={AddBoxIcon}
              {...iconFontSize}
              onClick={async () => {
                let changed = false; 

                if (currentConfig._id) {
                  await getConfig(currentConfig._id, data => {
                    const { __v, ...mc } = data

                    if (!isEqualObj(currentConfig, mc)) {
                      changed = true;
                      handleGetawayDialogOpen();
                    }
                  })
                }

                if (!changed && currentConfig._id)
                  setCurrentConfig(newConfig);
              }}
            />
          </Toolbar>
          <List dense key="configs" className={classes.list}>
            <TextField
              style={{ width: "100%", flexGrow: 1 }}
              placeholder={t("common.search")}
              variant={"outlined"}
              size={"small"}
              onInput={handleFilterSearch}
              value={searchFilter}
              InputProps={{
                classes: {
                  notchedOutline: classes.filterOutline,
                  focused: classes.filterOutline,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon/>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      title={t("common.clear_search")}
                      Icon={ClearIcon}
                      {...iconFontSize}
                      disabled={!searchFilter}
                      onClick={() => handleCleanUpFilterSearch()}
                    />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(configs)
              .map(item =>
                <List key={`configs_list_${item}`} disablePadding>
                  <ListItem key={item}
                    button
                    onClick={() => setOpenListItem({ ...openListItem, [item]: !openListItem[item] })}>
                    <ListItemText primary={<span style={{fontWeight: 'bold'}}>{item}</span>}/>
                    {openListItem[item] ? <ExpandLess/> : <ExpandMore/>}
                  </ListItem>
                  <Collapse in={openListItem[item]} timeout="auto" unmountOnExit>
                    <List dense disablePadding>
                      {configs[item].filter(({ name }) =>
                        name
                          .toLocaleLowerCase()
                          .includes(searchFilter.toLocaleLowerCase()))
                          .map(config =>
                            <ListItem key={config._id}
                              button
                              onClick={async () => {
                                let changed = false; 

                                if (currentConfig._id) {
                                  await getConfig(currentConfig._id, data => {
                                    const { __v, ...mc } = data

                                    if (!isEqualObj(currentConfig, mc)) {
                                      changed = true;
                                      handleGetawayDialogOpen(config._id);
                                    }
                                  })
                                } else {
                                  if (currentConfig['name'] || currentConfig['description'] || currentConfig['data']) {
                                    changed = true;
                                    handleGetawayDialogOpen(config._id);
                                  }
                                };

                                if (!changed)
                                  getConfig(config._id, setCurrentConfig);
                              }}
                              selected={currentConfig._id && currentConfig._id === config._id}
				  >

                              <Tooltip title={<h3>{config.name}</h3>}>
                                <ListItemText
                                  primaryTypographyProps={{
                                    style: {
                                      marginLeft: "10px",
                                      width: "80%",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    },
                                  }}
                                  primary={
                                    <Highlighter
                                      searchWords={[searchFilter]}
                                      style={{ whiteSpace: "nowrap" }}
                                      autoEscape={true}
                                      textToHighlight={config.name}
                                    />
                                  }
                                />
                              </Tooltip>
                              <ListItemSecondaryAction>
				  <span style={{ fontSize: 10, color: "#B6B6B6"}}>{config.type}</span>
                              </ListItemSecondaryAction>
                            </ListItem>
                          )
                      }
                    </List>
                  </Collapse>
                </List>
              )
            }
          </List>
        </Grid>
        <Grid item xs={9} sm={9} style={{ paddingTop: 10 }}>
          <Toolbar disableGutters variant="dense" style={{ display: "flex", justifyContent: "space-between"}}> 
            <Typography 
              component={"span"}
              className={classes.title}
              variant="h6"
              id="newConfigTitle"
            >
              {(!currentConfig._id && t("configs.new")) || ""}
            </Typography>
            <div style={{ display: "flex" }}>
              <IconButton
                title={t("configs.save")}
                Icon={SaveIcon}
                {...iconFontSize}
                disabled={ifDisabled('button','save') || !['name','scope','type'].every(k => currentConfig[k]) || equalToOrigConfig()}
                onClick={() => currentConfig._id ? updateConfig(currentConfig) : createConfig(currentConfig)}
              />
              <IconButton
                title={t("configs.clone")}
                Icon={FileCopyIcon}
                {...iconFontSize}
                disabled={ifDisabled('button','clone')}
                onClick={() => { cloneConfig(currentConfig._id) }}
              />
              <IconButton
                title={t("configs.download")}
                Icon={GetAppIcon}
                {...iconFontSize}
                disabled={ifDisabled('button','download')}
                onClick={downloadConfig}
              />
              <IconButton
                title={t("configs.delete")}
                Icon={DeleteIcon}
                {...iconFontSize}
                disabled={ifDisabled('button')}
                onClick={() => { handleDeleteDialogOpen(currentConfig._id, currentConfig.name) }}
              />
            </div>
          </Toolbar>
          <div>
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 14}}>
              <TextField
                required
                className={classes.dialogField}
                fullWidth
                inputRef={nameRef}
                value={currentConfig.name}
                disabled={ifDisabled('field')}
                size="small"
                variant="outlined"
                label={t("common.name")}
                onChange={e => setCurrentConfig({ ...currentConfig, name: e.target.value })}
              />
              <Autocomplete
                options={["custom", "public"]}
                required
                className={classes.dialogField}
                size="small"
                label={t("configs.scope")}
                getOptionLabel={(option) => option}
                onChange={(event, value) => setCurrentConfig({
                  ...currentConfig,
                  scope: value,
                  ...(value == "custom" ? { project: props.projectId } : {}),
                })}
                variant="outlined"
                value={currentConfig.scope}
                disabled={ifDisabled('field')}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label={t("configs.scope")}/>
                )}
              />
              <Autocomplete
                options={NLU_TYPE_AC_OPTS}
                required
                className={classes.dialogField}
                size="small"
                label={t("configs.type")}
                disabled={ifDisabled('field')}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => setCurrentConfig({ ...currentConfig, type: value?.value || "" })}
                variant="outlined"
                value={NLU_TYPE_AC_OPTS.find(item => item.value === currentConfig.type) || NLU_TYPE_AC_OPTS[0]}
                fullWidth
                renderOption={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label={t("configs.type")}/>
                )}
              />
            </div>
            {
              // CodeMirror styling added inside element because of uncommon stylization for CodeMirror.
              // Tried to add setSize (from documentation), didn't work properly, relativity didn't work too
            }
            <TextField
              value={currentConfig.description}
              disabled={ifDisabled('field')}
              className={classes.descriptionField}
              onFocus={() => {
                setDescriptionFocus(true);
                codemirrorRef.current.editor.display.wrapper.style.height = "557.5px"
              }}
              onBlur={() => {
                setDescriptionFocus(false);
                codemirrorRef.current.editor.display.wrapper.style.height = "595.5px"
              }}
              fullWidth
              name={t("common.description")}
              multiline
              rows={!descriptionFocus? 1: 3}
              size="small"
              variant="outlined"
              label={t("common.description")}
              onChange={e => setCurrentConfig({ ...currentConfig, description: e.target.value })}
            />
            <div className={classes.paper}>
              <CodeMirror
                ref={codemirrorRef}
                style={{marginTop: 10}}
                value={currentConfig.data}
                options={{
                  mode: "yaml",
                  lineNumbers: true,
                  readOnly: ifDisabled('field'),
                }}
                onBeforeChange={(editor, data, value) => {
                  setCurrentConfig({ ...currentConfig, data: value });
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  projectId: state.settings.projectId,
  modelConfigList:  state.settings.modelConfigList,
});

export default withRouter(connect(mapStateToProps)(withTranslation()(Configs)));

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { resources } from './translate.json';

i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: localStorage.getItem('i18nextLng') || 'en', //default language when load the website in browser
        fallbackLng: 'en', // use en if detected lng is not available

        //keySeparator: false, // we do not use keys in form messages.welcome
        ns: ['translation'],
        defaultNS: 'translation',
        keySeparator: '.',
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            useSuspense: false
        }
    });

export default i18n;

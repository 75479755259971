import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { MESSAGE_STATUS, SOME_SETTINGS }  from '../../../core/constants';
import { getData, postData }           from '../../../core/fetchService';
import { getRunConfigOption }             from '../../../core/utils';

import { setAuthData, setOAuthData, getOauthServer }       from '../../../features/settings';

function Copyright() {
	return getRunConfigOption('showCopyright') ? (
			<Box mt={8}>
		<Typography variant="body2" color="textSecondary" align="center">
			{'©'}
			&nbsp;
			{new Date().getFullYear()}
			&nbsp;
			<Link color="inherit" href="https://spitch.ch">
				Spitch
			</Link>
		</Typography>
			</Box>
    ) : null;
}

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export function OAuthCallback() {
  const dispatch = useDispatch();
  useEffect(() => {
    getData(`/oauth_login${window.location.search}`, dispatch, data => {
      console.log('oauth_login returned:', data);
      dispatch(setOAuthData(data));
 	 	}, {
      error_prefix: 'Login error',
    });
  },[]);
  return <span>authorizing...</span> // <Redirect to="/projects"/>;
}

function LocalSignIn() {
	const classes = useStyles();
	const inputEmail = useRef();
	const inputPassword = useRef();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const authorize = e =>
    postData(`/login`,
		{ "user": { "email": inputEmail.current.value, "password": inputPassword.current.value } },
		dispatch,
		data => {
			dispatch(setAuthData(data));
		});

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					{t('common.signin')}
				</Typography>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					id="email"
					label={t('common.email_address')}
					name="email"
					autoComplete="email"
					autoFocus
					inputRef={inputEmail}
				/>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					name="password"
					label={t('common.password')}
					type="password"
					id="password"
					autoComplete="current-password"
					inputRef={inputPassword}
					onKeyDown={e => e.key == "Enter" && authorize()}
				/>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					className={classes.submit}
          onClick={authorize}
				>
					{t('common.sign_in')}
				</Button>
			</div>
				<Copyright />
		</Container>
	);
}

function OauthSignIn(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const oauthServer = useSelector(getOauthServer);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('common.signin')}
        </Typography>
        <form method="POST" action={`https://${oauthServer}/oauth/authorize`}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label={"Oauth"+ t('common.email_address')}
            name="username"
            autoComplete="username"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('common.password')}
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <input hidden readOnly name="client_id" value="nlu_suite"/>
          <input hidden readOnly name="redirect_uri" value={window.location.origin +"/nlu_suite_callback"}/>
          <input hidden readOnly name="response_type" value="code"/>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {t('common.sign_in')}
          </Button>
        </form>
      </div>
      <Copyright />
    </Container>
  );
}

export default function SignIn() {
  const oauthServer = useSelector(getOauthServer);
  return oauthServer === null ? "wait..." : !oauthServer ? <LocalSignIn/> : <OauthSignIn/>;
}

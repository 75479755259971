import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

export default function MultiSelect({
  /* extra props */
  label = "unlabled",
  required = false,

  /* defaults for mui Autocomplete's props */
  size = "small",
  ...other_props
}) {
  return (
    <Autocomplete
      {...{
        size, ...other_props,
      }}
      multiple
      disableCloseOnSelect
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            id={"access-checkbox" + option._id}
            icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
            checkedIcon={<CheckBoxIcon fontSize="small"/>}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </>
      )}
      renderInput={params => (
        <TextField
          {...{...params, required}}
          variant="outlined"
          label={label}
        />
      )}
    />);
}

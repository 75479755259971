import React from "react";
import { withRouter } from "react-router";
import { useTranslation, withTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";

import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import TranslateIcon from "@material-ui/icons/Translate";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { getLang, setLang }     from "../../../features/settings";
import { lang }                 from "../../../i18n/translate.json";

function LanguageSelect() {
  const currentLang = useSelector(getLang);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (lng) => {
    setAnchorEl(null);
    dispatch(setLang(lng));
    await i18n.changeLanguage(lng);
  };

  return (
    <div>
      <Button
        size="small"
        variant="contained"
        color="primary"
        aria-controls="fade-menu"
        title={t("common.change_language")}
        onClick={handleClick}>
        <TranslateIcon fontSize="small" style={{ marginRight: 7 }}/>
        {(i18n.language && lang?.find(l => l.value === i18n.language)?.label) || undefined}
        <ExpandMoreIcon fontSize="small" style={{ marginLeft: 7 }}/>
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => handleClose(currentLang)}
        TransitionComponent={Fade}
      >
        {
          lang.map((lng, index) => {
            return (
              <MenuItem key={index} onClick={() => handleClose(lng.value)}>{lng.label}</MenuItem>
            );
          })
        }
      </Menu>
    </div>
  );
}

const mapStateToProps = (state) => ({
  lang: state.settings.lang,
});

export default withRouter(
  connect(mapStateToProps)(withTranslation()(LanguageSelect))
);

import { createSlice } from '@reduxjs/toolkit';
import { getData } from '../core/fetchService';

export const modelFilters = createSlice({
	name: 'modelFilters',
	initialState: {
    filters: [],
	},
	reducers: {
		setModelFilters: (state, action) => {
      state.filters = action.payload;
    },
	},
});

export const { setModelFilters } = modelFilters.actions;

/** Note: not used yet (instead, state.modelFilters is used with the help of mapStateToProps
 *
 * export const getModelFilters = state => state.modelFilters.filters;
 */

export const fetchModelFilters = projectId => dispatch => 
  getData(`/api/filter?project=${projectId}&downgrade=true`, dispatch, data => {
      dispatch(setModelFilters(data.filters));
  });

export default modelFilters.reducer;

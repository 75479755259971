import React from "react";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

export default function NumberField({
  /* extra props */

  /* defaults for mui TextField's props */
  size = "small",
  variant = "outlined",
  fullWidth = true,
  ...other_props
}) {
  return (
    <TextField
      {...{
        size, variant, fullWidth,
        ...other_props,
      }}
    />
  );
}


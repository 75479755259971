import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Menu,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { LookupScope } from "../../../core/constants";

const propTypes = Object.freeze({
  isOpen: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
});

export default function DownloadDialog({ isOpen, onAccept, onReject }) {
  const { t } = useTranslation();
  const [scope, setScope] = useState(LookupScope.Custom);

  useEffect(() => setScope(LookupScope.Custom), [isOpen]);

  const handleScopeChange = (event) => setScope(event.target.value);

  return (
    <>
      <Dialog fullWidth open={isOpen} maxWidth="sm">
        <DialogTitle>{t("entities.download_dialog_title")}</DialogTitle>
        <DialogContent dividers>
          <FormControl fullWidth>
            <TextField
              select
              size="small"
              variant="outlined"
              label={t("entities.entity_scope")}
              defaultValue={LookupScope.Custom}
              onChange={handleScopeChange}
            >
              {Object.values(LookupScope).map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onReject()}>{t("common.cancel")}</Button>
          <Button
            autoFocus
            color="primary"
            variant="contained"
            onClick={() => onAccept(scope)}
          >
            {t("common.download")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DownloadDialog.propTypes = propTypes;

import Location                                         from '../core/location';
import {
  ClearToken, GetToken,
  decodeMessage, showSuccess, showError,
}                                                       from '../core/utils';
import { setIsLoading }                                 from "../features/settings";

async function request(url, method, options = {}) {
  const token = GetToken();
  const opt_headers = options.headers || {};

  delete options.headers;

  const auto_content_type = opt_headers['Content-Type'] === null;

  if (auto_content_type)
    delete opt_headers['Content-Type'];

	// Default options are marked with *
	return await fetch(url, {
		method, // *GET, POST, PUT, DELETE, etc.
		mode: 'cors', // no-cors, *cors, same-origin
		cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		credentials: 'same-origin', // include, *same-origin, omit
		headers: {
      ...(auto_content_type ? {} : { 'Content-Type': 'application/json' }),
			'authorization': 'bearer ' + token,
      ...opt_headers,
		},
		redirect: 'follow', // manual, *follow, error
		referrerPolicy: 'no-referrer', // no-referrer, *client
    ...options
	});
}

function requestWrap(methodFunc, { url, data, dispatch, on_success, options = {} }) {
  const show_loading = !options.disable_show_loading;
  show_loading && dispatch(setIsLoading(true));
  const args = [_postData, _putData, _uploadFile, _fetchAndDownloadData].includes(methodFunc) ? [data] : [];
  let kind = 'fetch';
  return methodFunc(url, ...args)
    .then(data => {
      if (data.error)
        throw data.error;
      if (options.request_manager?.cancelled)
        return 'ok:cancelled';
      kind = 'on_success';
      const res = on_success && on_success(data);
      return res !== undefined ? res : data; // for 'await' statements used with requestWrap and its callers
    })
    .catch(error => {
      if (options.request_manager?.cancelled)
        return 'error:cancelled';
      void options.on_error?.(error);
      if (error.name != "AbortError") {
        console.error(`[${methodFunc.name}::${kind}]:`, error);
        const error_prefix = options.error_prefix ? options.error_prefix +': ' : '';
        const p = options.message_prefix || 'NO_MESSAGE_PREFIX';
        const m = error.message || '';
        const t = options.t;
        showError(dispatch, t)(error_prefix + (t? decodeMessage(m, t, p) : m));
      }
      return { error }; // for 'await's
    })
    .finally(() => {
      show_loading && dispatch(setIsLoading(false));
    });
}

const _getRawResponse = async (response, is_json) => {
  const data = await checkStatus(response, is_json);
  return { data, response, error: data.error };
};

const __getData = async (url, is_json) => await _getRawResponse(await request(url, 'GET'), is_json);

async function __fetchData(url, data = {}, method = 'POST', is_json) {
	const response = await request(url, method, {
		body: JSON.stringify(data) // body data type must match "Content-Type" header
	});
  return await _getRawResponse(response, is_json);
}

const _getData = async url => (await __getData(url, true)).data;
const _getRawData = url => __getData(url);

const _fetchData = async (url, data, method) => (await __fetchData(url, data, method, true)).data;

const _postData = async (url, data) => await _fetchData(url, data, 'POST');
const _putData = async (url, data) => await _fetchData(url, data, 'PUT');

const _fetchAndDownloadData = (url, data) => __fetchData(url, data, 'POST');

async function _uploadFile(url, formData) {
	const response = await request(url, 'POST', {
		headers: {
			// 'Content-Type': 'multipart/form-data', <--- does not work w/o setting mfd boundary
			// 'Content-Type': 'application/x-www-form-urlencoded',
			'Content-Type': null,
		},
		body: formData // body data type must match "Content-Type" header
	});
	return await checkStatus(response, true);
}

async function _deleteData(url) {
	const response = await request(url, 'DELETE');
	return await checkStatus(response, true);
}

function checkStatus(response, is_json) {
  if (response.ok && response.status >= 200 && response.status < 300 || response.status == 500)
    return is_json || response.status == 500? response.json() : response.blob();
  if (response.status == 401) {
    ClearToken();
    Location.push('/sign-in');
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

export const getRawData = (url, dispatch, on_success, options) =>
  requestWrap(_getRawData, { url, dispatch, on_success, options });

export const getData = (url, dispatch, on_success, options) =>
  requestWrap(_getData, { url, dispatch, on_success, options });

export const postData = (url, data, dispatch, on_success, options) =>
  requestWrap(_postData, { url, data, dispatch, on_success, options });

export const putData = (url, data, dispatch, on_success, options) =>
  requestWrap(_putData, { url, data, dispatch, on_success, options });

export const fetchAndDownloadData = (url, data, dispatch, on_success, options) =>
  requestWrap(_fetchAndDownloadData, { url, data, dispatch, on_success, options });

export const uploadFile = (url, data, dispatch, on_success, options) => {
  const f = on_success && (data => {
    data.message && showSuccess(dispatch, options.t)(data.message);
    on_success(data);
  });
  return requestWrap(_uploadFile, { url, data, dispatch, on_success: f, options });
}

export const deleteData = (url, dispatch, on_success, options) =>
  requestWrap(_deleteData, { url, dispatch, on_success, options });

/** FIXME: In case of multiple failures, when actions are based on requestWrap,
 *    there will be a lot of error pop-up flushing. Need to suppress this annoying behaviour,
 *    gather all errors from actions and keep/show then in a conveient way
 */
export const fetchAll = actions => Promise.all(actions);

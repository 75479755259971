import { createBrowserHistory } from 'history';
import { filterKeys } from './utils';

import {
  Users,
  UserGroups,
  Projects,
  Datasets,
  Intents,
  Slots,
  Entities,
  Filters,
  Annotation,
  Similarity,
  TrainConfigs,
  Train,
  Tests,
  AutoAnnotation,
  Batches,
}                                                       from "../view/pages";

const ROUTES = [
// section               param          Component       options
  ['users',              'user_id',     Users,          { is_admin: true, }],
  ['user_groups',        'group_id',    UserGroups,     { is_admin: true, }],
  ['projects',           'projectId',   Projects,       {    exact: true, }],
  ['datasets',           'projectId',   Datasets,                          ],
  ['intents',            'projectId',   Intents,                           ],
  ['entities',           'projectId',   Entities,                          ],
  ['slots',              'projectId',   Slots,                             ],
  ['annotation',         'projectId',   Annotation,     {
           more_params: ['datasetId'],                                    }],
  ['filters',            'projectId',   Filters,                           ],
  ['configs',            'projectId',   TrainConfigs,                      ],
  ['train',              'projectId',   Train,                             ],
  ['tests',              'projectId',   Tests,                             ],
  ['auto_annotation',    'projectId',   AutoAnnotation,                    ],
  ['batches',            'projectId',   Batches,                           ],
  // ['similarity',         'projectId',   Similarity,     { lmenu: false }   ],
];

function Location() {
  this.Routes = ROUTES.map(([section, param, Component, options]) => ({
    section,
    path: '/'+ [section, ':'+ param, ...(options?.more_params || []).map(p => ':'+ p)].join('/'),
    Component,
    ...options,
  }));
  this.Routes.filter(r => ['users', 'user_groups', 'projects'].includes(r.section))
    .forEach(r => { r.path = [r.path, '/'+ r.section].flat() });
  this._history = createBrowserHistory();

  this.getHistoryObj = () => this._history; // Note: only for usage as React Router's 'history' param

  if (0) {
  this.push =     (...args) => { console.log('PUSH', ...args); this._history.push(...args); }
  this.replace =  (...args) => { console.log('REPLACE', ...args); this._history.replace(...args); }
  } else {
  this.push =     this._history.push;
  this.replace =  this._history.replace;
  }

  this._parse = () => {
    const { pathname } = this._history.location;
    const [ _, section, projectId, itemId ] = pathname.split('/');
    return { section, projectId, itemId };
  };
  this._make = l => '/'+ Object.values(l).join('/').replace(/\/+$/, '');

  this.getQueryParams = defaults => {
    const q = new URLSearchParams(this._history.location.search);
    return { ...defaults, ...filterKeys(Object.fromEntries(q.entries()), Object.keys(defaults)) };
  };
  this._setQueryParams = values => {
    const q = new URLSearchParams(this._history.location.search);
    Object.entries(values).forEach(([name, value]) => {
      if (value != undefined) {
        if (q.has(name))
          q.set(name, value);
        else
          q.append(name, value);
      } else {
        q.delete(name);
      }
    });
    return String(q) ? '?'+ q : '';
  };
  this.setQueryParams = values => {
    this.push(this._history.location.pathname + this._setQueryParams(values));
  };

  const is_known = routes => routes.map(r => r[0]).includes(this._parse().section);
  this.isKnownRoute = () => is_known(ROUTES);
  this.isRouteWith = param => is_known(ROUTES.filter(r => r[1] == param));
  this.isRouteWithProjectId = () => this.isRouteWith('projectId');

  this.getSection = () => this._parse().section;
  this.getProjectId = () => this._parse().projectId;
  this.getDatasetId = () => this._parse().itemId;

  this.changeProject = (id, item) => {
    const l = this._parse();
    if (!this.isRouteWithProjectId())
      return console.error('(should not be here) Unknown route: /'+ l.section);
    // console.log('changeProject', id, l);
    if (l.projectId == id)
      return;
    const itemId = l.section == 'annotation' ? item : '';
    this[l.projectId ? 'push' : 'replace'](this._make({ ...l, projectId: id, itemId }));
  };

  this.show = (section, id) => {
    const l = this._parse();
    // console.log('show', section, id, l);
    this.push(this._make({ ...l, section }) + this._setQueryParams({ show: id }));
  };
}

export default new Location();

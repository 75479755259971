import React, { Component } from 'react';
import { connect }          from "react-redux";
import { withTranslation }  from 'react-i18next';

import propTypes from 'prop-types';

import { withStyles }   from '@material-ui/styles';
import Button           from '@material-ui/core/Button';
import Typography       from '@material-ui/core/Typography';

import { MESSAGE_STATUS }   from '../../core/constants';
import { setMessageState }  from '../../features/messageInfo';

const styles = () => ({
    container: {
        position: 'relative',
        display: 'inline-block',
        boxSizing: 'border-box'
    },
    fileInput: {
        opacity: 0,
        position: 'absolute',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
    },
    fileName: {
        display: 'inline',
        marginLeft: '10px!important'
    }
});

class Upload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileName: null,
        }
    };

    static defaultProps = {
        fileTypeRegex: null,// /([a-zA-Z0-9\s_\\.\-\(\):])+(.json|.csv)$/i,
        onFileLoad: (e) => undefined,
        fileNameShow: true,
        btnComponent: undefined,
        btnLabel: 'common.upload',
    };

    static propTypes = {
        fileTypeRegex: propTypes.object,
        onFileLoad: propTypes.func,
        fileNameShow: propTypes.bool,
        btnComponent: propTypes.object,
        btnLabel: propTypes.string,
    };

    exclusiveProps = [
        'fileTypeRegex',
        'onFileLoad'
    ];

    onInputChange = ({ target }) => {
        const file = target.files[0];
        if (this.props.fileTypeRegex && file.name.match(this.props.fileTypeRegex) == null) {
            this.props.setMessageState({
                snackBarMessages: "File must have extension JSON or CSV",
                snackBarVariant: MESSAGE_STATUS.ERROR,
                snackBarState: true,
            });
        } else {
            this.setState({ fileName: file.name });

            const formData = new FormData();
            formData.append('file', file);
            this.props.onFileLoad(file.name, formData);
            if (this.props.passOutside) {
                this.props.passOutside(file.name.slice(0, file.name.lastIndexOf('.')))
            }
        }
    };

    render() {
        const { classes, fileNameShow, btnComponent, btnLabel, size, t } = this.props;
        const { fileName } = this.state;

        return (
            <div className={styles.container}>
                <input
                    className={styles.fileInput}
                    type="file"
                    multiple
                    onChange={this.onInputChange}
                    id="uploadFile"
                    style={{ display: 'none' }}
                    value=""
                />
                <label htmlFor="uploadFile">
                  {btnComponent || <Button variant="outlined" size={size || 'medium'} component="span">
                    {t(btnLabel)}
                  </Button>}
                </label>
                {fileNameShow && fileName && <Typography className={classes.fileName} variant="subtitle2" noWrap>{fileName}</Typography>}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setMessageState: obj => {
            dispatch(setMessageState(obj));
        }
    };
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(withTranslation()(Upload)))

import React from "react";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

export default function NumberField({
  /* extra props */
  adjustValue = undefined,
  onChange = undefined,
  title = undefined,

  /* defaults for mui TextField's props */
  size = "small",
  variant = "outlined",
  fullWidth = true,
  ...other_props
}) {
  const adjustToRange = v => {
    const min = other_props.inputProps?.min;
    if (min !== undefined && v < min)
      return min;
    const max = other_props.inputProps?.max;
    if (max !== undefined && v > max)
      return max;
    return v;
  }

  const Field = () => (
    <TextField
      type="number"
      {...{
        size, variant, fullWidth,
        ...other_props,
        ...(onChange ? {
          onChange: event => {
            const v = adjustToRange(event.target.value);
            const value = adjustValue ? adjustValue(v) : v;
            onChange({ ...event, target: { ...event.target, value } });
          },
        } : {}),
      }}
    />);

  return title
    ? <Tooltip title={title}>{Field()}</Tooltip>
    : Field();
}


import React from "react";
import MaterialUIAutocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export default function Autocomplete(props) {
  const {
    /* extra props */
    fontSize = "0.9rem",
    onValueChange = null, // must be defined

    /* defaults for mui Autocomplete's props */
    size = "small",
    getOptionLabel = option => option.label,
    autoComplete = true,
    onChange = (event, value) => onValueChange(value),

    ...other_props
  } = props;

  const lprops = {
    ...{ size, getOptionLabel, autoComplete, onChange },
    ...other_props,
  };

  return (
    <MaterialUIAutocomplete
      { ...lprops }
      renderInput={(params) => {
        const newParams = {
          ...params,
          InputProps: {
            ...params.InputProps,
            style: { fontSize },
          },
        };
        return (
          <TextField
            label={props.label || ''}
            {...newParams}
            size="small"
            fullWidth
            variant="outlined"
          />
        );
      }}
    />);
}

export let AutocompleteFilterOptions = createFilterOptions();

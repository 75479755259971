import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import * as pkg from '../../../../package.json'

const useStyles = makeStyles((theme) => ({
  table: {
    padding: theme.spacing(2),
  },
  tableCell: {
    border: `1px dotted ${theme.palette.divider}`,
  }
}));

export default function InfoPopover(props) {
  const { t } = useTranslation()
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'info-popover' : undefined;

  const cell = data => (
    <td className={classes.tableCell}>
      <Typography>{data}</Typography>
    </td>
  );

  return (
    <div>
      <IconButton aria-describedby={id} variant="contained" onClick={handleClick} title={t('info.title')}>
        <InfoOutlinedIcon color="inherit" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <table cellSpacing="0" cellPadding="5" className={classes.table}>
          <tbody>
            <tr>
              {cell( t('info.version') )}
              {cell( pkg.version )}
            </tr>
          </tbody>
        </table>
      </Popover>
    </div>
  );
}

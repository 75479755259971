import React from 'react';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";

export default function CloseBar(props) {
  const {onClose, title} = props

  return (
    <AppBar style={{ background: "#3F51B5" }} position="relative">
      <Toolbar>
        <Typography style={{ flex: 1 }} variant="h6" component="div">
          {title}
        </Typography>
        <IconButton
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon/>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
